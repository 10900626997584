import React, { memo, useState } from 'react';
import { UserSignInStyled, LoginForm, InputStyled, SignUp, Row, RememberMe, ForgotPassword } from './style.jsx';
import IdentifyyLogo from 'components/common/identifyy_logo';
import GoogleButton from '../common/google_button';
import FacebookButton from '../common/facebook_button';
import EnvatoButton from '../common/envato_button';
import SubmitButton from '../common/submit_button';
import Column from '../common/column';
import Header from '../common/header';
import { signInRequest } from 'requests/users/devise/sign_in';
import { showNotification } from 'components/FlashMessages';

const UserSignIn = params => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const onClickSignIn = e => {
    e.preventDefault();

    const user = { email, password, rememberMe };
    const data = {
      utf8: '✓',
      authentication_token: $('meta[name=\'csrf-token\']').attr('content'),
      user,
      commit: 'Login',
    };

    signInRequest(data)
      .then(() => {
        setEmail('');
        setPassword('');
      })
      .catch(() => {
        showNotification({ level: 'error', message: I18n.t('devise.failure.not_found_in_database') });
      });
  };

  return (
    <UserSignInStyled>
      <Column>
        <IdentifyyLogo />
        <LoginForm>
          <Header>{I18n.t('users.login.title')}</Header>
          <InputStyled
            placeholder={I18n.t('users.login.email')}
            id='email'
            type='email'
            value={email}
            onChange={e => setEmail(e.target.value)}
          ></InputStyled>
          <InputStyled
            placeholder={I18n.t('users.login.password')}
            id='password'
            type='password'
            value={password}
            onChange={e => setPassword(e.target.value)}
          ></InputStyled>
          <Row>
            <RememberMe
              label={I18n.t('users.login.remember_me')}
              id='remember_me'
              checked={rememberMe}
              onChange={() => setRememberMe(value => !value)}
            ></RememberMe>
            <ForgotPassword href={'password/new'}>{I18n.t('users.login.forgot_password')}</ForgotPassword>
          </Row>
          <SubmitButton className='primary' disabled={!email || !password} type='submit' onClick={onClickSignIn}>
            {I18n.t('users.login.btn')}
          </SubmitButton>
        </LoginForm>
        <FacebookButton />
        <GoogleButton />
        {params.envato_login && <EnvatoButton />}
        <SignUp>
          {I18n.t('devise.registrations.create_new')} <a href={'sign_up'}>{I18n.t('users.login.sign_up')}</a>
        </SignUp>
      </Column>
    </UserSignInStyled>
  );
};

export default memo(UserSignIn);
